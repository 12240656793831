import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import UserContext from "Contexts/UserContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export const useAPIURL = () => {
  const { env } = useContext(UserContext);
  const suffix =
    !env || env === "PROD"
      ? ""
      : env === "DEV"
      ? ".develop"
      : `.${env.toLowerCase()}`;
  return `https://api${suffix}.leanspace.io`;
};

export const useTenants = () => {
  const { tenants } = useLeanspaceAPI();
  return useQuery(["tenants"], () =>
    tenants.getAll({
      size: 100,
    })
  );
};

export const useTenant = (id: string) => {
  const { tenants } = useLeanspaceAPI();
  return useQuery(["tenant", id], () => tenants.get(id));
};
