import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { FormHelperText } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { Lock } from "react-feather";

import { COGNITO_POOLS } from "../constants";
import UserContext from "../Contexts/UserContext";

type LoginForm = {
  env: "DEV" | "DEMO" | "PROD";
  username: string;
  password: string;
};

const Login = () => {
  const { login } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [formvalues, setFormValues] = useState<LoginForm>({
    env: "DEV",
    username: "",
    password: "",
  });
  const [hasLoginError, setLoginError] = useState(false);
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent
  ) => {
    if (event !== null) {
      const { name, value } = event.target;
      setFormValues({
        ...formvalues,
        [name]: value,
      });
    }
  };
  const attemptLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoginError(false);
      const { env, username, password } = formvalues;
      if (!env || !username.trim() || !password.trim()) {
        setLoginError(true);
        return;
      }

      await Amplify.configure({
        Auth: COGNITO_POOLS[env],
      });
      const user = await Auth.signIn(username, password);
      const {
        accessToken: { jwtToken },
      } = user.signInUserSession;

      localStorage.setItem("env", env);
      login(jwtToken);
    } catch (err: unknown) {
      setLoginError(true);
      if (err instanceof Error) {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Wrong login or password.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <Lock />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={attemptLogin} noValidate sx={{ mt: 1 }}>
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="env-label">Environment</InputLabel>
            <Select
              error={hasLoginError && !formvalues.env}
              onChange={handleChange}
              name="env"
              label="Environment"
              labelId="env-label"
              value={formvalues.env}
            >
              {Object.entries(COGNITO_POOLS).map(([key, pool]) => (
                <MenuItem key={key} value={key}>
                  {"displayName" in pool ? pool.displayName : key}
                </MenuItem>
              ))}
            </Select>
            {hasLoginError && !formvalues.env && (
              <FormHelperText>Environment is required</FormHelperText>
            )}
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={handleChange}
            id="username"
            label="User name"
            name="username"
            autoComplete="username"
            value={formvalues.username}
            error={hasLoginError && !formvalues.username}
            helperText={
              hasLoginError && !formvalues.username && "Username is required"
            }
          />
          <TextField
            margin="normal"
            onChange={handleChange}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formvalues.password}
            autoComplete="current-password"
            error={hasLoginError && !formvalues.password}
            helperText={
              hasLoginError && !formvalues.password && "Password is required"
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
