import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { LeanspaceProvider } from "@leanspace/js-client/dist/react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import omit from "lodash/omit";
import { SnackbarProvider } from "notistack";
import React from "react";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import Login from "./Components/Login";
import Router from "./Components/Router";
import { COGNITO_POOLS } from "./constants";
import UserContext from "./Contexts/UserContext";

dayjs.extend(relativeTimePlugin);

const queryClient = new QueryClient();

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8CD3B4",
    },
    secondary: {
      main: "#645E9D",
    },
    error: {
      main: "#9A031E",
    },
    warning: {
      main: "#E0A458",
    },
  },
});

const getToken = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  const {
    accessToken: { jwtToken },
  } = user.signInUserSession;
  return jwtToken;
};

function App() {
  const [isAmplifyReady, setAmplifyReady] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, updateToken] = useState<string>();
  const [didNotFindTenant, setDidNotFindTenant] = useState(false);
  const env = localStorage.getItem("env") as "DEV" | "DEMO" | "PROD";

  useEffect(
    function loadTenantConfig() {
      setDidNotFindTenant(false);
      const load = async () => {
        try {
          await Amplify.configure({
            Auth: omit(COGNITO_POOLS[env], "displayName"),
          });
          setAmplifyReady(true);

          const currentUser = await Auth.currentAuthenticatedUser();
          if (currentUser.signInUserSession) {
            const {
              accessToken: { jwtToken },
            } = currentUser.signInUserSession;
            updateToken(jwtToken);
            setLoggedIn(true);
          }
        } catch (error) {
          setDidNotFindTenant(true);
        }
      };
      load();
    },
    [env]
  );

  const onLogin = (token: string) => {
    updateToken(token);
    setLoggedIn(true);
  };

  const contextValue = {
    token,
    login: onLogin,
    isLoggedIn,
    env: env || "PROD",
  };

  const suffix =
    !env || env === "PROD"
      ? ""
      : env === "DEV"
      ? ".develop"
      : `.${env.toLowerCase()}`;
  const baseURL = `https://api${suffix}.leanspace.io`;

  return isAmplifyReady ? (
    <ThemeProvider theme={darkTheme}>
      <UserContext.Provider value={contextValue}>
        <LeanspaceProvider
          clientOptions={{
            getToken,
            baseURL,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                {isLoggedIn ? <Router /> : <Login />}
              </SnackbarProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </LeanspaceProvider>
      </UserContext.Provider>
    </ThemeProvider>
  ) : didNotFindTenant ? (
    <>Failed to load tenant information — did you update the config.js file?</>
  ) : null;
}

export default App;
